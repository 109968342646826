<template>
  <form :class="$style.form" @submit.prevent="submitForm">
    <h2>Клиент</h2>
    <div :class="$style.dealer">
      <div>
        {{ dealerAccount.name }}<br />
        ID {{ dealerAccount.id }}
      </div>
      <div :class="$style.discounts">
        <span
          v-if="
            dealerAccount.ieCartDiscountIsAvailable &&
            settings.ieCartDiscountIsAvailable
          "
        >
          Доступна скидка для корзины ИП: {{ settings.ieCartDiscount }}%
        </span>
        <span
          v-if="
            dealerAccount.llcCartDiscountIsAvailable &&
            settings.llcCartDiscountIsAvailable
          "
        >
          Доступна скидка для корзины ООО: {{ settings.llcCartDiscount }}%
        </span>
        <span
          v-if="
            dealerAccount.rwCartDiscountIsAvailable &&
            settings.rwCartDiscountIsAvailable
          "
        >
          Доступна скидка для корзины УС: {{ settings.rwCartDiscount }}%
        </span>
        <span v-if="settings.rwDiscountIsAvailable">
          Доступна спец цена для товаров УС
        </span>
      </div>
    </div>
    <label class="label">Реквизиты клиента</label>
    <Select
      :class="$style.select"
      v-model="clientRequesiteId"
      :options="selectClientRequesite"
      nameField="clientRequesiteId"
      placeholder="Выберите реквизиты клиента"
      with-trash-icon
      @resetValue="resetSelect"
      @change="setDeliveryPrice"
    />
    <label class="label">Условия доставки</label>
    <Select
      :class="$style.select"
      v-model="deliveryType"
      :options="selectDeliveryType"
      nameField="deliveryType"
      placeholder="Выберите тип доставки"
      with-trash-icon
      @resetValue="resetSelect"
      @change="setDeliveryPrice"
    />
    <div
      v-if="deliveryType !== $options.DELIVERY_TYPE.SELFDELIVERY"
      :class="$style.exressDeliveryBlock"
    >
      <label class="label">Экспресс доставка</label>
      <Checkbox
        :checked="isExpressDelivery"
        @change="handleChangeExpressDelivery"
      />
    </div>
    <div v-if="deliveryType !== $options.DELIVERY_TYPE.SELFDELIVERY">
      <label class="label">Адрес доставки</label>
      <Select
        :class="$style.select"
        v-model="deliveryAddressId"
        :options="selectDeliveryAddress"
        nameField="deliveryAddressId"
        placeholder="Выберите адрес доставки"
        with-trash-icon
        @resetValue="resetSelect"
        @change="handleChangeDeliveryAdress"
      />
    </div>
    <div
      v-if="
        isAddressNotInsideInPolygon &&
        deliveryType !== $options.DELIVERY_TYPE.SELFDELIVERY
      "
      :class="$style.deliveryPrice"
    >
      <label class="label">Цена доставки для доставки вне полигона</label>
      <input v-model.number="deliveryPrice" />
    </div>
    <label class="label">Получатель</label>
    <Select
      :class="$style.select"
      v-model="selectRecipientId"
      :options="selectRecipient"
      nameField="selectRecipientId"
      placeholder="Выберите получателя"
      with-trash-icon
      @resetValue="resetSelect"
      @change="setDeliveryPrice"
    />
    <br />
    <p :class="$style.help">
      Список получателей формируется из доступных адресов доставки клиента.
    </p>
    <label class="label">Способ оплаты</label>
    <Select
      :class="$style.select"
      v-model="payment"
      :options="selectPayment"
      nameField="payment"
      placeholder="Выберите способ оплаты"
      with-trash-icon
      @resetValue="resetSelect"
      @change="setDeliveryPrice"
    />
    <div :class="$style.date">
      <label class="label"> Дата доставки</label> <br />
      <input type="date" v-model="date" :min="minDate" />
    </div>
    <div :class="$style.exressDeliveryBlock">
      <label class="label">Оформить в резерв</label>
      <Checkbox :checked="isReserve" @change="isReserve = $event" />
    </div>
    <div v-if="!products.length" :class="$style.noProducts">
      Не добавлено ни одного товара
    </div>
    <ul v-else :class="$style.products">
      <li
        v-for="(product, index) in products"
        :key="index"
        :class="$style.product"
      >
        <div :class="$style.column">
          Артикул: {{ product.product.vendorСode }}
        </div>
        <div :class="$style.column" :title="product.name">
          {{
            product.name.length > 40
              ? product.name.substr(0, 40) + '...'
              : product.name
          }}
        </div>
        <div :class="$style.column">
          Кол-во ИП
          <input
            v-model.number="product.stockIE"
            type="number"
            :min="0"
            :max="setMaxQuantity('IE', product.product)"
            @input="checkPricePolicyPrice(index)"
            @change="handleChangeProductQuantity(product)"
          />
        </div>
        <div :class="$style.column">
          Кол-во ООО
          <input
            v-model.number="product.stockLLC"
            type="number"
            :min="0"
            :max="setMaxQuantity('LLC', product.product)"
            @input="checkPricePolicyPrice(index)"
            @change="handleChangeProductQuantity(product)"
          />
        </div>
        <div :class="$style.column" v-if="!product.supplierId">
          Кол-во УС
          <input
            v-model.number="product.stockRW"
            type="number"
            :min="0"
            :max="setMaxQuantity('RW', product.product)"
            @input="checkPricePolicyPrice(index)"
            @change="
              resetExpressDelivery(), handleChangeProductQuantity(product)
            "
          />
        </div>
        <div :class="$style.column" v-else>
          Кол-во УСК
          <input
            v-model.number="product.stockRWC"
            type="number"
            :min="0"
            :max="setMaxQuantity('RWC', product.product)"
            @change="
              resetExpressDelivery(), handleChangeProductQuantity(product)
            "
          />
        </div>
        <div :class="$style.column">
          <div>Цена: {{ product.priceFromCatalog }} ₽</div>
          <div :class="{ [$style.specialPrice]: product.specialPriceActive }">
            Спец цена: {{ product.discountPrice.price || '-' }} ₽
          </div>
          Спец цена от
          {{ product.quantityForDiscountPrice }}
        </div>

        <div :class="$style.column">
          <a
            :class="$style.remove"
            href=""
            title="Удалить товар"
            @click.prevent="removeProduct(index)"
          >
            <Icon name="trash" />
          </a>
        </div>
      </li>
    </ul>
    <div :class="$style.newProduct">
      <h2>Добавление товара</h2>
      <h3>Ценовая политика дилера: {{ dealerAccount.pricePolicy.name }}</h3>
      <Search
        :class="$style.search"
        v-model.trim="searchItem"
        :items="searchProducts"
        :is-show="isShow"
        :is-pagination="isPagination"
        @clear="clearSearch"
        @scroll="handleScroll"
      >
        <div
          v-for="(item, index) in searchProducts"
          :key="index"
          :class="$style.item"
        >
          <div :class="$style.link">
            <img :src="s3_link + item.mainImage" alt="" />
            <span>{{ item.name }}</span>
            <span>
              Артикул <br />
              {{ item.vendorСode }}
            </span>
            <span>
              Цена <br />
              {{ item.price }}
            </span>
            <span>
              кол-во ИП <br />
              {{ item.quantityInIE }}
            </span>
            <span>
              кол-во ООО <br />
              {{ item.quantityInLLC }}
            </span>
            <span>
              кол-во УС <br />
              {{ item.quantityInRW }}
            </span>
            <span>
              <Button @click="addNewProduct(item)">Добавить</Button>
            </span>
          </div>
        </div>
      </Search>
    </div>
    <div :class="$style.prices">
      <div>Цена доставки: {{ deliveryPrice }}</div>
      <div>
        Цена на ИП: {{ totalIEPriceWithDiscount }}
        <span> (Скидка: {{ discountSizeIE }}%)</span>
      </div>
      <div>
        Цена на ООО: {{ totalLLCPriceWithDiscount }}
        <span> (Скидка: {{ discountSizeLLC }}%)</span>
      </div>
      <div>
        Цена на УС: {{ totalRWPriceWithDiscount }}
        <span> (Скидка: {{ discountSizeRW }}%)</span>
      </div>
      <div>
        Цена на УСК: {{ totalRWCPriceWithDiscount }}
        <span> (Скидка: {{ discountSizeRW }}%)</span>
      </div>
    </div>
    <h1 :class="$style.total">Общая cумма: {{ totalPrice }} ₽</h1>
    <div :class="$style.actions">
      <Button type="submit">Оформить</Button>
      <Button type="button" @click="$router.back()">Назад</Button>
    </div>
    <Loader :isLoading="isLoading" />
  </form>
</template>

<script>
import delivery from '@/delivery'
import { formatInputDate } from '@/helpers'
import { debounce } from '@/helpers/debounce.js'
import { DELIVERY_TYPE } from '@/constants/deliveryType'
import { WAREHOUSES } from '@/constants/warehouses'
import { PAYMENT_TYPES } from '@/constants/paymentTypes'
import { isPointInsidePolygon } from '@/helpers/addSellers'

import Search from '@/components/atoms/InputSearchable.vue'
import Button from '@/components/atoms/Button.vue'
import Select from '@/components/atoms/Select.vue'
import Icon from '@/components/atoms/Icon.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import notifications from '@/mixins/notifications'
import Checkbox from '@/components/atoms/Checkbox.vue'

export default {
  components: { Search, Button, Select, Icon, Loader, Checkbox },
  mixins: [notifications],
  DELIVERY_TYPE,
  WAREHOUSES,
  PAYMENT_TYPES,
  data() {
    return {
      isAddressNotInsideInPolygon: false,
      warehouse: WAREHOUSES.IE,
      clientRequesiteId: null,
      deliveryType: DELIVERY_TYPE.DELIVERY,
      comment: '',
      deliveryAddressId: null,
      payment: PAYMENT_TYPES.CASH,
      vendorCode: '',
      date: formatInputDate(new Date(Date.now() + 86400000)),
      minDate: formatInputDate(new Date(Date.now() + 86400000)),
      products: [],
      dealerAccount: {
        id: null,
        name: '',
        clientRequesites: [],
        deliveryAddresses: [],
      },
      selectWarehouse: [
        { value: WAREHOUSES.IE, text: 'ИП' },
        { value: WAREHOUSES.LLC, text: 'ООО' },
        { value: WAREHOUSES.RW, text: 'УС' },
        { value: WAREHOUSES.RWC, text: 'УСК' },
      ],
      selectDeliveryType: [
        { value: DELIVERY_TYPE.DELIVERY, text: 'Доставка' },
        { value: DELIVERY_TYPE.SELFDELIVERY, text: 'Самовывоз' },
        { value: DELIVERY_TYPE.DELIVERY_TO_CLIENT, text: 'Доставка клиенту' },
      ],
      selectPayment: [
        { value: PAYMENT_TYPES.CASH, text: 'Наличными при получени' },
        { value: PAYMENT_TYPES.BILL, text: 'На расчетный счет' },
      ],
      selectRecipientId: null,
      limit: 10,
      searchItem: '',
      searchProducts: [],
      isShow: false,
      isSearch: false,
      isLoading: false,
      isPagination: true,
      page: 0,
      totalPages: 0,
      settings: {},
      deliveryPrice: 0,
      isExpressDelivery: false,
      isReserve: false,
    }
  },
  computed: {
    deliveryAddress() {
      return this.dealerAccount?.deliveryAddresses?.find(
        (item) => item.id === this.deliveryAddressId,
      )
    },
    recipient() {
      return this.dealerAccount?.recipients?.find(
        (item) => item.id === this.selectRecipientId,
      )
    },
    client() {
      return this.dealerAccount?.clientRequesites?.find(
        (item) => item.id === this.clientRequesiteId,
      )
    },
    selectClientRequesite() {
      return (
        this.dealerAccount?.clientRequesites?.map((item) => ({
          value: item.id,
          text: item.name,
        })) ?? []
      )
    },
    selectRecipient() {
      return (
        this.dealerAccount?.recipients?.map((item) => ({
          value: item.id,
          text: `${item.lastName} ${item.firstName}  ${item.middleName}`,
        })) ?? []
      )
    },
    selectDeliveryAddress() {
      return (
        this.dealerAccount?.deliveryAddresses?.map((item) => ({
          value: item.id,
          text: item.cardName,
        })) ?? []
      )
    },
    s3_link() {
      return process.env.VUE_APP_S3_URL
    },
    discountSizeLLC() {
      const isDiscount =
        this.settings.llcCartDiscountIsAvailable &&
        this.dealerAccount.llcCartDiscountIsAvailable &&
        this.payment === PAYMENT_TYPES.CASH
      return isDiscount ? this.settings.llcCartDiscount : 0
    },
    discountSizeIE() {
      const isDiscount =
        this.settings.ieCartDiscountIsAvailable &&
        this.dealerAccount.ieCartDiscountIsAvailable &&
        this.payment === PAYMENT_TYPES.CASH
      return isDiscount ? this.settings.ieCartDiscount : 0
    },
    discountSizeRW() {
      const isDiscount =
        this.settings.rwCartDiscountIsAvailable &&
        this.dealerAccount.rwCartDiscountIsAvailable &&
        this.payment === PAYMENT_TYPES.CASH
      return isDiscount ? this.settings.rwCartDiscount : 0
    },
    totalLLCPrice() {
      return this.products.reduce((total, item) => {
        let price = item.price
        if (
          item.discountPrice.price &&
          this.getStockProductTotalForSpecPrice(item.id) >=
            item.quantityForDiscountPrice
        ) {
          price = item.discountPrice.price
        }

        return price * (item.stockLLC || 0) + total
      }, 0)
    },
    totalIEPrice() {
      return this.products.reduce((total, item) => {
        let price = item.price
        if (
          item.discountPrice.price &&
          this.getStockProductTotalForSpecPrice(item.id) >=
            item.quantityForDiscountPrice
        ) {
          price = item.discountPrice.price
        }

        return price * (item.stockIE || 0) + total
      }, 0)
    },
    totalRWPrice() {
      return Number(
        this.products
          .reduce((total, item) => {
            let price = item.price
            if (
              this.settings?.rwDiscountIsAvailable &&
              item.discountPrice.price &&
              this.getStockProductTotalForSpecPrice(item.id) >=
                item.quantityForDiscountPrice
            ) {
              price = item.discountPrice.price
            }

            return price * (item.stockRW || 0) + total
          }, 0)
          .toFixed(2),
      )
    },
    totalRWCPrice() {
      return Number(
        this.products
          .reduce((total, item) => {
            let price = item.price
            if (
              this.settings?.rwDiscountIsAvailable &&
              item.discountPrice.price &&
              this.getStockProductTotalForSpecPrice(item.id) >=
                item.quantityForDiscountPrice
            ) {
              price = item.discountPrice.price
            }

            return price * (item.stockRWC || 0) + total
          }, 0)
          .toFixed(2),
      )
    },
    totalPrice() {
      const deliveryPrice = this.products?.length ? this.deliveryPrice : 0

      return Number(
        (
          this.totalIEPrice +
          this.totalLLCPrice +
          this.totalRWPrice +
          this.totalRWCPrice +
          deliveryPrice
        ).toFixed(2),
      )
    },
    totalLLCPriceWithDiscount() {
      return this.getDiscountPrice(this.totalLLCPrice, this.discountSizeLLC)
    },
    totalIEPriceWithDiscount() {
      return this.getDiscountPrice(this.totalIEPrice, this.discountSizeIE)
    },
    totalRWPriceWithDiscount() {
      return this.getDiscountPrice(this.totalRWPrice, this.discountSizeRW)
    },
    totalRWCPriceWithDiscount() {
      return this.getDiscountPrice(this.totalRWCPrice, this.discountSizeRW)
    },
    totalDiscountPrice() {
      return Number(
        this.totalLLCPriceWithDiscount +
          this.totalIEPriceWithDiscount +
          this.totalRWPriceWithDiscount +
          this.totalRWCPriceWithDiscount,
      )
    },
  },
  watch: {
    async searchItem() {
      this.isShow = true
      await this.handleSearch()
    },
  },
  async created() {
    await this.getDealer()
    await this.getSettings()
    this.handleSearch = debounce(this.updateSearchList, 500)
  },
  methods: {
    async handleChangeDeliveryAdress() {
      await this.geocodeAddress()

      this.setDeliveryPrice()
    },
    resetSelect(resetField) {
      this[resetField] = ''
      if (resetField === 'deliveryAddressId') {
        this.deliveryPrice = 0
        return
      }
      this.setDeliveryPrice()
    },
    async getDealer() {
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getById(this.$route.params.id)

      if (!value?.pricePolicyId) {
        value.pricePolicy = {name: 'Спец AddSeller'}
      }

      if (error) return

      this.dealerAccount = value
    },
    resetExpressDelivery() {
      if (this.isExpressDelivery) {
        this.isExpressDelivery = false
        this.showNotification(
          'К сожалению, экспресс доставка не доступна, т.к. выбран товар из удаленного склада',
          'error',
        )
      }
    },
    handleChangeProductQuantity(product) {
      this.setDeliveryPrice()
      if (product.stockRW > product.product.quantityInRW) {
        product.stockRW = product.product.quantityInRW
        return
      }

      if (product.stockRWС > product.product.quantityInRWС) {
        product.stockRWС = product.product.quantityInRWС
        return
      }

      if (product.stockIE > product.product.quantityInIE) {
        product.stockIE = product.product.quantityInIE
        return
      }

      if (product.stockLLC > product.product.quantityInLLC) {
        product.stockLLC = product.product.quantityInLLC
        return
      }
    },
    handleChangeExpressDelivery() {
      const hasRWProducts = this.products.some(
        (product) => product.stockRW || product.stockRWC,
      )

      if (hasRWProducts) {
        this.isExpressDelivery = false
        this.showNotification(
          'К сожалению, экспресс доставка не доступна, т.к. добавлены товары с удаленного склада',
          'error',
        )
        return
      }

      if (!this.settings?.deliveryExpressIsAvailable) {
        this.isExpressDelivery = false
        this.showNotification(
          'К сожалению, экспресс доставка не доступна',
          'error',
        )
        return
      }

      if (this.isAddressNotInsideInPolygon) {
        this.isExpressDelivery = false
        this.showNotification(
          'К сожалению, экспресс доставка вне полигона не доступна',
          'error',
        )
        return
      }

      this.isExpressDelivery = !this.isExpressDelivery
      this.setDeliveryPrice()
    },
    async updateSearchList() {
      this.searchProducts = []
      await this.getSearchList()
    },
    checkPricePolicyPrice(index) {
      if (this.products[index].product?.discountPrices) {
        const discountPrice = this.products[index].discountPrice
        if (
          this.products[index].stockIE +
            this.products[index].stockLLC +
            this.products[index].stockRW >=
            discountPrice.quantity &&
          discountPrice.price > 0
        ) {
          this.products[index].specialPriceActive = true
          this.products[index].price = discountPrice.price
        } else {
          this.products[index].specialPriceActive = false
          this.products[index].price = this.products[index].priceFromCatalog
        }
      }
    },
    async addNewProduct(product) {
      if (this.products.find((item) => item.id === product.id)) {
        this.showNotification('Такой товар уже был добавлен', 'error')
        return
      }

      if (!this.dealerAccount?.pricePolicyId) {
        const defaultPolicy = product.discountPrices.find(
          (x) => x.pricePolicy.isDefault === true,
        )
        this.dealerAccount.pricePolicy = defaultPolicy.pricePolicy
        this.dealerAccount.pricePolicyId = defaultPolicy.pricePolicyId
      }

      const discountPrice = product.discountPrices.find(
        (x) => x.pricePolicyId === this.dealerAccount.pricePolicyId,
      )
      const productPrice = product.price

      this.products.push({
        id: product.id,
        stockIE: 0,
        stockLLC: 0,
        stockRW: 0,
        stockRWC: 0,
        product: product,
        name: product.name,
        price: productPrice,
        priceFromCatalog: product.price,
        discountPrice: discountPrice,
        supplierId: product.supplierId,
        quantityForDiscountPrice: discountPrice.quantity,
        specialPriceActive: false,
      })
    },
    removeProduct(index) {
      this.products.splice(index, 1)
    },
    getDiscountPrice(price, discount) {
      return price - Math.round((price / 100) * discount)
    },
    getStockProductTotalForSpecPrice(id) {
      const product = this.products.find((item) => item.id === id)
      const rwDiscountIsAvailable = this.settings.rwDiscountIsAvailable
      if (product) {
        const stockRW = rwDiscountIsAvailable
          ? product.stockRW + product.stockRWC
          : 0
        return product.stockLLC + product.stockIE + stockRW
      }
      return 0
    },
    productsLLC() {
      return this.products
        .filter((item) => item.stockLLC > 0)
        .map((item) => {
          let productPrice = item.price
          if (
            this.getStockProductTotalForSpecPrice(item.id) >=
            item.quantityForDiscountPrice
          ) {
            productPrice = item.discountPrice.price || item.price
          }
          return {
            productId: item.id,
            productPrice,
            quantity: item.stockLLC,
            name: item.name,
          }
        })
    },
    productsIE() {
      return this.products
        .filter((item) => item.stockIE > 0)
        .map((item) => {
          let productPrice = item.price
          if (
            this.getStockProductTotalForSpecPrice(item.id) >=
            item.quantityForDiscountPrice
          ) {
            productPrice = item.discountPrice.price || item.price
          }
          return {
            productId: item.id,
            productPrice,
            quantity: item.stockIE,
            name: item.name,
          }
        })
    },
    productsRW() {
      return this.products
        .filter((item) => item.stockRW > 0)
        .map((item) => {
          let productPrice = item.price
          if (
            this.getStockProductTotalForSpecPrice(item.id) >=
            item.quantityForDiscountPrice
          ) {
            productPrice = item.discountPrice.price || item.price
          }
          return {
            productId: item.id,
            productPrice,
            quantity: item.stockRW,
            name: item.name,
          }
        })
    },
    productsRWC() {
      return this.products
        .filter((item) => item.stockRWC > 0)
        .map((item) => {
          let productPrice = item.price
          if (
            this.settings.rwDiscountIsAvailable &&
            this.getStockProductTotalForSpecPrice(item.id) >=
              item.quantityForDiscountPrice
          ) {
            productPrice = item.discountPrice.price || item.price
          }
          return {
            productId: item.id,
            productPrice,
            quantity: item.stockRWC,
            name: item.name,
          }
        })
    },

    async submitForm() {
      let emptyStocksError = ''
      this.products.forEach((item) => {
        const stocks =
          item.stockIE + item.stockLLC + item.stockRW + item.stockRWC
        if (!stocks) {
          emptyStocksError = item.name + '-выбрано 0 единиц;\n'
        }
      })

      if (emptyStocksError) {
        this.showNotification(`${emptyStocksError}`, 'error')
        return
      }
      if (!this.clientRequesiteId) {
        this.showNotification('Выберите реквизиты клиента', 'error')
        return
      }
      if (
        this.deliveryType !== DELIVERY_TYPE.SELFDELIVERY &&
        !this.deliveryAddressId
      ) {
        this.showNotification('Выберите адрес доставки', 'error')
        return
      }
      if (!this.selectRecipientId) {
        this.showNotification('Выберите получателя', 'error')
        return
      }
      if (!this.products.length) {
        this.showNotification('Не добавлено ни одного товара', 'error')
        return
      }

      if (!this.payment) {
        this.showNotification('Выберите способ оплаты', 'error')
        return
      }

      if (!this.deliveryType) {
        this.showNotification('Выберите способ доставки', 'error')
        return
      }

      const deliveryPrice =
        this.deliveryType === DELIVERY_TYPE.SELFDELIVERY
          ? 0
          : this.deliveryPrice

      const order = {
        deliveryAt: new Date(this.date),
        comment: this.comment,
        dealerId: this.dealerAccount.id,
        clientRequesiteId: this.clientRequesiteId,
        deliveryAddress: null,
        deliveryType: this.deliveryType,
        recipient: this.recipient,
        isExpressDelivery: this.isExpressDelivery,
        isReserve: this.isReserve,
        meta: [],
      }

      const warehouses = {
        IE: {
          warehouse: WAREHOUSES.IE,
          price: this.totalIEPriceWithDiscount,
          discountSize: this.discountSizeIE,
          payment: this.payment,
          products: this.productsIE(),
          deliveryAt: new Date(this.date),
          deliveryPrice: deliveryPrice,
        },
        LLC: {
          warehouse: WAREHOUSES.LLC,
          price: this.totalLLCPriceWithDiscount,
          discountSize: this.discountSizeLLC,
          payment: this.payment,
          products: this.productsLLC(),
          deliveryAt: new Date(this.date),
          deliveryPrice: deliveryPrice,
        },
        RW: {
          warehouse: WAREHOUSES.RW,
          price: this.totalRWPriceWithDiscount,
          discountSize: this.discountSizeRW,
          payment: this.payment,
          products: this.productsRW(),
          deliveryAt: new Date(this.date),
          deliveryPrice: deliveryPrice,
        },
        RWC: {
          warehouse: WAREHOUSES.RWC,
          price: this.totalRWCPriceWithDiscount,
          discountSize: this.discountSizeRW,
          payment: this.payment,
          deliveryAt: new Date(this.date),
          products: this.productsRWC(),
          deliveryPrice: deliveryPrice,
        },
      }

      if (this.deliveryType !== DELIVERY_TYPE.SELFDELIVERY) {
        order.deliveryAddress = {
          id: this.deliveryAddress.id,
          cardName: this.deliveryAddress.cardName,
          city: this.deliveryAddress.city,
          street: this.deliveryAddress.street,
          office: this.deliveryAddress.office,
          comment: this.deliveryAddress.comment,
        }

        order.comment = this.deliveryAddress.comment
      }

      if (this.productsIE().length) {
        order.meta.push(warehouses.IE)
      }
      if (this.productsLLC().length) {
        order.meta.push(warehouses.LLC)
      }
      if (this.productsRW().length) {
        order.meta.push(warehouses.RW)
      }
      if (this.productsRWC().length) {
        order.metaRWC = warehouses.RWC
      }

      this.isLoading = true
      const result = await delivery.AddwineCore.OrdersActions.create(order)
      this.isLoading = false

      result.error
        ? this.showNotification('Ошибка создания заказа', 'error')
        : this.$router.push('/sellers/orders')
    },
    async clearSearch() {
      this.isShow = false
      this.isSearch = false
      this.searchItem = ''
    },
    async getSearchList() {
      this.isLoading = true
      const query = {
        limit: this.limit,
        search: this.searchItem,
        page: this.page,
      }

      const result = await delivery.AddwineCore.ProductsActions.getSearchList(
        query,
      )
      if (result.error) {
        this.error = result.error
      } else {
        this.totalPages = Math.ceil(result.value.meta.count / this.limit)
        this.searchProducts = [...this.searchProducts, ...result.value.data]
      }
      this.isLoading = false
    },
    setMaxQuantity(warehouse, product) {
      switch (warehouse) {
        case WAREHOUSES.IE:
          return product.quantityInIE
        case WAREHOUSES.LLC:
          return product.quantityInLLC
        case WAREHOUSES.RW:
          return product.quantityInRW
        case WAREHOUSES.RWC:
          return product.quantityInRWC
        default:
          return 1
      }
    },
    async handleScroll(e) {
      if (!this.isLoading) {
        const prevScrollHeight = e.target.scrollHeight
        let scroll = prevScrollHeight - e.target.scrollTop
        if (scroll <= 400 && this.page < this.totalPages) {
          this.page++
          await this.getSearchList()
        }
      }
    },
    async getSettings() {
      const result = await delivery.AddwineCore.SellersSettingsActions.get()
      if (result.error) return

      this.settings = result.value
    },
    async geocodeAddress() {
      const { value, error } =
        await delivery.YandexAPI.GeoCodeActions.geocodeAddress(
          this.deliveryAddress.city,
          this.deliveryAddress.street,
        )

      if (error) return

      const isInside = this.isPointInsidePolygon(
        value?.data?.response?.GeoObjectCollection?.featureMember[0]?.GeoObject?.Point?.pos
          .split(' ')
          .reverse(),
        [JSON.parse(this.settings?.polygonValue)],
      )

      if (!isInside) {
        this.isAddressNotInsideInPolygon = true
        this.deliveryPrice = 0
        this.showNotification(
          'Адрес доставки не попадает в полигон, но вы можете указать цену самостоятельно в поле цена доставки для доставки вне полигона',
          'warning',
        )
        return
      }

      this.isAddressNotInsideInPolygon = false

      return isInside
    },
    setDeliveryPrice() {
      if (this.deliveryType === DELIVERY_TYPE.SELFDELIVERY) {
        this.isExpressDelivery = false
        this.deliveryPrice = 0
        return
      }

      if (this.deliveryAddress) {
        const price = this.totalDiscountPrice
          ? this.totalDiscountPrice
          : this.totalPrice

        this.recipient
          ? this.setDeliveryPriceToRecipient(price)
          : this.setDeliveryPriceToDealer(price)
      }
    },
    setDeliveryPriceToRecipient(price) {
      if (this.isAddressNotInsideInPolygon) return

      if (!this.settings?.deliveryToClientIsAvailable) return

      const isPriceMoreBorder =
        price > this.settings?.deliveryToClientBorderPrice

      this.deliveryPrice = isPriceMoreBorder
        ? 0
        : this.isExpressDelivery && this.settings?.deliveryExpressIsAvailable
        ? this.settings?.deliveryExpressPrice
        : this.settings?.deliveryToClientPrice
    },
    setDeliveryPriceToDealer(price) {
      if (this.isAddressNotInsideInPolygon) return

      if (!this.settings?.deliveryIsAvailable) return

      const isPriceMoreBorder = price > this.settings?.deliveryBorderPrice

      this.deliveryPrice = isPriceMoreBorder
        ? 0
        : this.isExpressDelivery && this.settings?.deliveryExpressIsAvailable
        ? this.settings?.deliveryExpressPrice
        : this.settings?.deliveryPrice
    },
    isPointInsidePolygon(point, polygons) {
      return isPointInsidePolygon(point, polygons)
    },
  },
}
</script>

<style lang="scss" module>
.form {
  padding: 3rem;
  h2 {
    margin-bottom: 0rem;
  }
  .dealer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .discounts {
      display: flex;
      flex-direction: column;
    }
  }

  .exressDeliveryBlock {
    display: flex;
    gap: 1rem;
  }
  .select {
    margin-bottom: 0.5rem;
    & > div {
      color: $black-gray;
    }
  }
  select {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }
  .date {
    margin-bottom: 1rem;
  }
  .help {
    margin: -1rem 0 1rem;
  }

  .deliveryPrice {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
  }

  .newProduct {
    h2 {
      color: $light-gray;
    }
    margin-bottom: 1rem;
    .vendorCode {
      flex: 1 1 auto;
      margin-right: 1rem;
      > input {
        width: 100%;
      }
    }
    > [type='submit'] {
      align-self: flex-end;
    }
  }
  .noProducts {
    text-align: center;
    margin-bottom: 1rem;
  }
  .products {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    .product {
      margin-bottom: 1rem;
      display: grid;
      grid-template-columns: 1fr minmax(300px, auto) repeat(4, 1fr) 20px;
      &:nth-child(even) {
        background: $extra-light-gray;
      }
      .column {
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > label {
          margin-right: 0.5rem;
        }
        .remove {
          color: $error;
        }
        &:last-child {
          align-items: flex-end;
        }
      }
    }
    .specialPrice {
      color: $cyan;
      text-decoration: underline;
    }
  }
  .search {
    margin-bottom: 5rem;
    max-width: 80.5rem;
  }
  .item {
    display: flex;
    align-items: center;

    border-bottom: 0.063rem solid $extra-light-gray;
    &:last-child {
      border-bottom: none;
    }
    .link {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 0 0.5rem;
      text-decoration: none;
      width: 100%;
      padding: 1rem 0;
      color: $black-gray;
      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
  .total {
    text-align: right;
    margin-bottom: 1rem;
  }
  .actions {
    display: flex;
    > [type='submit'] {
      margin-right: 0.5rem;
    }
  }
}
</style>
